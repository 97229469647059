<template>
  <div class="main-container">
    <div class="child-container">
      <div class="q-px-sm">
        <div class="selected-panel">
          <div class="selected-panel__text">
            <p>{{ $t("sidebar.selectedPanel") }}</p>
          </div>
          <div class="selected-panel__details q-pa-md">
            <div v-if="panelDetails">
              <p class="selected-panel__details__title">
                {{ panelDetails.name }}
              </p>
              <div class="row q-mt-sm">
                <img class="panel-img" :src="textureUrl" />
                <div class="selected-panel__details__content">
                  <p>
                    {{ Math.trunc(panelDetails.size.width * 1000) }}mm x
                    {{ Math.trunc(panelDetails.size.height * 1000) }}mm
                  </p>
                </div>
              </div>
            </div>
            <div>
              <q-btn
                no-caps
                style="background: #23e7a5; color: black"
                class="q-mt-sm"
                @click="handlePanelSelectionPopup(!panelSelectionOpen)"
              >
                <img
                  style="padding-right: 6px"
                  src="@/assets/icons/change-panel-icon.svg"
                />
                {{ $t("sidebar.changePanel") }}
                <q-tooltip>
                  {{ $t("sidebar.changePanel") }}
                </q-tooltip>
              </q-btn>
            </div>
          </div>
        </div>
        <div class="panel-sidebar-toggle">
          <el-switch
            class="panel-sidebar-toggle__input"
            v-model="orientation"
            :active-icon="VerticalPanel"
            :inactive-icon="HorizontalPanel"
            active-color="white"
            inactive-color="white"
          />
        </div>
      </div>
      <div class="divider"></div>
      <div class="q-px-sm">
        <div>
          <p class="selected-panel__details" style="font-weight: 600">
            {{ $t("panelMenu.gridStructure") }}
          </p>
        </div>
        <div class="q-py-md" style="text-align: left">
          <div class="q-pb-sm">
            <el-input
              v-model="margin"
              class="input-field"
              :prefix-icon="GridStructurePanelIcon"
              :class="{ 'is-error': !isValidMargin }"
            >
              <template #suffix>
                <span>cm</span>
              </template>
            </el-input>
            <q-tooltip anchor="center left" self="center middle">
              {{ $t("panelMenu.margin") }}
            </q-tooltip>
          </div>
          <div class="q-pb-sm">
            <el-input
              v-model="verticalSpacing"
              class="input-field"
              :prefix-icon="GridStructurePanelIcon"
              :class="{ 'is-error': !isValidVerticalSpacing }"
            >
              <template #suffix>
                <span>cm</span>
              </template>
            </el-input>
            <q-tooltip anchor="center left" self="center middle">
              {{ $t("panelMenu.verticalSpacing") }}
            </q-tooltip>
          </div>
          <div>
            <el-input
              v-model="horizontalSpacing"
              class="input-field"
              :prefix-icon="GridStructurePanelIcon"
              :class="{ 'is-error': !isValidHorizontalSpacing }"
            >
              <template #suffix>
                <span>cm</span>
              </template>
            </el-input>
            <q-tooltip anchor="center left" self="center middle">
              {{ $t("panelMenu.horizontalSpacing") }}
            </q-tooltip>
          </div>
        </div>
      </div>
      <div class="divider"></div>
      <div class="q-px-sm">
        <div>
          <p class="selected-panel__details" style="font-weight: 600">
            {{ $t("panelMenu.offset") }}
          </p>
        </div>
        <div class="q-py-md" style="text-align: left">
          <el-input
            v-model="offset"
            class="input-field"
            :prefix-icon="GridStructurePanelIcon"
            :class="{ 'is-error': !isValidOffset }"
          >
            <template #suffix>
              <span>cm</span>
            </template>
          </el-input>
        </div>
      </div>
      <div style="text-align: center">
        <q-btn
          no-caps
          style="background: #23e7a5; color: black; width: 80%"
          class="q-mt-sm"
          @click="closeSidebar"
        >
          {{ $t("sidebar.complete") }}
          <q-tooltip>
            {{ $t("sidebar.complete") }}
          </q-tooltip>
        </q-btn>
      </div>
      <div class="divider"></div>
      <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12" style="text-align: center">
          <q-btn
            style="background: white; color: black; width: 70px"
            @click="(e) => handleCreateRestrictedArea(e)"
          >
            <img src="@/assets/icons/x-icon.svg" />
          </q-btn>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12" style="text-align: center">
          <q-btn
            style="background: #f44336; color: black; width: 70px"
            @click="handleDeleteSolarGroup"
          >
            <img
              style="width: 25px; height: 25px"
              src="@/assets/icons/delete.svg"
            />
          </q-btn>
        </div>
      </div>
    </div>
  </div>
  <PanelSelection
    v-if="panelSelectionOpen"
    :handlePanelSelectionPopup="handlePanelSelectionPopup"
    :panelOrientation="orientation"
    :likedPanelProp="likedPanel"
    :horizontalPanels="horizontalPanels"
    :verticalPanels="verticalPanels"
    :activeUserId="activeUserId"
    @panelSelected="handleChangeSelectPanel"
    :anonymousUser="anonymousUser"
    :starClicked="starClicked"
    :likedPanelId="likedPanelId"
  />
</template>

<script>
import { h } from "vue";
import PanelSelection from "@/components/RightSidebar/PanelSelection.vue";
import API from "@/api/API.js";
import { mapGetters } from "vuex";
import { convertToMillimeters } from "@/utils/units.js";

export default {
  name: "SolarSidebar",
  components: { PanelSelection },
  props: [
    "selectedSolarGroup",
    "areas",
    "horizontalPanels",
    "verticalPanels",
    "activeUserId",
    "populateArea",
    "removeSolarGroupPanels",
    "addOffsetToPanels",
    "removeSolarGroup",
    "anonymousUser",
    "sample",
    "likedPanel",
    "starClicked",
    "likedPanelId",
  ],
  emits: ["setupCompleted", "solarGroupDeleted"],
  data() {
    return {
      panelSelectionOpen: false,
      orientation: this.selectedSolarGroup.orientation,
      margin: this.selectedSolarGroup.margin,
      horizontalSpacing: this.selectedSolarGroup.horizontalSpacing,
      verticalSpacing: this.selectedSolarGroup.verticalSpacing,
      offset: this.selectedSolarGroup.offset,
      HorizontalPanel: () =>
        h("img", {
          src: "/assets/icons/horizontal-panel.svg",
          alt: "Horizontal Icon",
        }),
      VerticalPanel: () =>
        h("img", {
          src: "/assets/icons/vertical-panel.svg",
          alt: "Vertical Icon",
        }),
      GridStructurePanelIcon: () =>
        h("img", {
          src: "/assets/icons/grid-structure-panel.svg",
          alt: "Grid Structure Panel Icon",
        }),
    };
  },
  computed: {
    ...mapGetters(["customPanels"]),
    panelDetails() {
      let panel = (
        this.orientation ? this.verticalPanels : this.horizontalPanels
      ).find((panel) => panel.id === this.selectedSolarGroup.panelId);
      if (!panel) {
        panel = (this.customPanels || []).find(
          (panel) => panel.id === this.selectedSolarGroup.panelId
        );
      }
      return panel;
    },
    textureUrl() {
      return `/assets/textures/solar_panel_texture_half_cell_${this.panelDetails.textureId}_horizontal.png`;
    },
    placementReady() {
      if (
        !this.isValidMargin ||
        !this.isValidOffset ||
        !this.isValidHorizontalSpacing ||
        !this.isValidVerticalSpacing ||
        !this.selectedSolarGroup.panelId
      )
        return false;
      return true;
    },
    isValidMargin() {
      return !isNaN(this.margin) && this.margin > -1 && this.margin < 100;
    },
    isValidOffset() {
      return !isNaN(this.offset) && this.offset > -1 && this.offset < 100;
    },
    isValidHorizontalSpacing() {
      return (
        !isNaN(this.horizontalSpacing) &&
        this.horizontalSpacing > -1 &&
        this.horizontalSpacing < 100
      );
    },
    isValidVerticalSpacing() {
      return (
        !isNaN(this.verticalSpacing) &&
        this.verticalSpacing > -1 &&
        this.verticalSpacing < 100
      );
    },
  },
  watch: {
    margin() {
      this.margin = this.fixParameterValue(this.margin);

      if (this.margin === "") return;
      this.handleCompleteUpdateSolarGroup();
    },
    offset() {
      this.offset = this.fixParameterValue(this.offset);

      if (this.offset === "") return;
      this.handleCompleteUpdateSolarGroup();
    },
    verticalSpacing() {
      this.verticalSpacing = this.fixParameterValue(this.verticalSpacing);

      if (this.verticalSpacing === "") return;
      this.handleCompleteUpdateSolarGroup();
    },
    horizontalSpacing() {
      this.horizontalSpacing = this.fixParameterValue(this.horizontalSpacing);

      if (this.horizontalSpacing === "") return;
      this.handleCompleteUpdateSolarGroup();
    },
    orientation() {
      this.handleCompleteUpdateSolarGroup();
    },
    selectedSolarGroup(newSolarGroup) {
      if (newSolarGroup) {
        this.orientation = newSolarGroup.orientation;
        this.margin = newSolarGroup.margin + "";
        this.horizontalSpacing = newSolarGroup.horizontalSpacing + "";
        this.verticalSpacing = newSolarGroup.verticalSpacing + "";
        this.offset = newSolarGroup.offset + "";
      }
    },
  },
  mounted() {
    const rsb = document.getElementById("right-side-bar");
    rsb.style.height = "100vh";
  },
  beforeUnmount() {
    const rsb = document.getElementById("right-side-bar");
    rsb.style.height = "auto";
  },
  methods: {
    handlePanelSelectionPopup(open) {
      this.panelSelectionOpen = open;
    },
    async handleCompleteUpdateSolarGroup(panelSelected = false) {
      if (!this.placementReady) return;
      const nothingChanged = this.nothingChanged();
      const onlyOffsetChanged = this.onlyOffsetChanged();
      const offsetDifference = this.offset - this.selectedSolarGroup.offset;

      this.selectedSolarGroup.verticalSpacing = parseFloat(
        this.verticalSpacing
      );
      this.selectedSolarGroup.horizontalSpacing = parseFloat(
        this.horizontalSpacing
      );
      this.selectedSolarGroup.margin = parseFloat(this.margin);

      this.selectedSolarGroup.offset = parseFloat(this.offset);

      this.selectedSolarGroup.orientation = this.orientation;

      if (
        this.selectedSolarGroup.instancedMesh &&
        this.selectedSolarGroup.instancedMesh.instancesCount > 0
      ) {
        if (onlyOffsetChanged) {
          this.addOffsetToPanels(this.selectedSolarGroup, offsetDifference);
        } else if (nothingChanged && !panelSelected) {
          return;
        } else {
          this.removeSolarGroupPanels(this.selectedSolarGroup);
        }
      }
      if (!this.sample) {
        API.airteam3DViewer.updateDefaultValues({
          offset: convertToMillimeters(this.offset),
          orientation: this.orientation ? "vertical" : "horizontal",
          vertical_spacing: convertToMillimeters(this.verticalSpacing),
          horizontal_spacing: convertToMillimeters(this.horizontalSpacing),
          panel_id: this.selectedSolarGroup.panelId,
          panel_margin: convertToMillimeters(this.margin),
        });
      }
      if (!onlyOffsetChanged) this.populateArea(this.selectedSolarGroup);
    },
    nothingChanged() {
      let nothingChanged =
        this.selectedSolarGroup.offset === parseFloat(this.offset);

      nothingChanged =
        this.selectedSolarGroup.verticalSpacing ===
        parseFloat(this.verticalSpacing);

      nothingChanged =
        nothingChanged &&
        this.selectedSolarGroup.horizontalSpacing ===
          parseFloat(this.horizontalSpacing);

      nothingChanged =
        nothingChanged &&
        this.selectedSolarGroup.margin === parseFloat(this.margin);

      nothingChanged =
        nothingChanged &&
        this.selectedSolarGroup.orientation === this.orientation;

      return nothingChanged;
    },
    onlyOffsetChanged() {
      const offsetChanged =
        this.selectedSolarGroup.offset !== parseFloat(this.offset);

      let nothingElseChanged =
        this.selectedSolarGroup.verticalSpacing ===
        parseFloat(this.verticalSpacing);

      nothingElseChanged =
        nothingElseChanged &&
        this.selectedSolarGroup.horizontalSpacing ===
          parseFloat(this.horizontalSpacing);

      nothingElseChanged =
        nothingElseChanged &&
        this.selectedSolarGroup.margin === parseFloat(this.margin);

      nothingElseChanged =
        nothingElseChanged &&
        this.selectedSolarGroup.orientation === this.orientation;

      return offsetChanged && nothingElseChanged;
    },
    async handleDeleteSolarGroup() {
      await this.removeSolarGroup(this.selectedSolarGroup);
      this.$emit("solarGroupDeleted");
    },
    async handleChangeSelectPanel(panel) {
      this.selectedSolarGroup.panelId = panel.id;
      this.panelSelectionOpen = false;
      this.handleCompleteUpdateSolarGroup(true);
    },
    closeSidebar() {
      if (!this.placementReady) {
        this.verticalSpacing = this.selectedSolarGroup.verticalSpacing;
        this.horizontalSpacing = this.selectedSolarGroup.horizontalSpacing;
        this.margin = this.selectedSolarGroup.margin;
        this.offset = this.selectedSolarGroup.offset;
        this.orientation = this.selectedSolarGroup.orientation;
      }
      this.$emit("setupCompleted");
    },
    fixParameterValue(parameter) {
      parameter = parameter?.replace(/\s/g, "");

      if (parameter?.includes(",")) {
        parameter = parameter.replace(/,/g, ".");
      }

      if (parameter?.includes(".")) {
        const decimalPoints = parameter.split(".")[1];
        if (decimalPoints.length > 3) {
          parameter = parseFloat(parameter).toFixed(3);
        }
      }
      return parameter;
    },
    handleCreateRestrictedArea(e) {
      this.$emit("restrictedAreasPlacement", e);
    },
  },
};
</script>

<style lang="scss" scoped>
.main-container {
  background-color: #27292c;
  padding: 20px 10px;
  height: 100%;
  overflow: scroll;
}

.child-container {
  padding: 10px 5px 50px;
  border-radius: 5px;
  background-color: #333537;
}

.selected-panel {
  border: 1px solid #23e7a5;
  border-radius: 5px;

  &__text {
    text-align: left;
    border-radius: 3px 3px 0 0;
    font-weight: 500;
    font-size: 15px;
    padding: 8px 0 8px 6px;
    color: black;
    background: #23e7a5;
  }

  &__details {
    color: white;
    text-align: left;

    &__title {
      font-weight: 500;
      padding: 10px;
      background: #3b3c3d;
      border-radius: 5px 5px 0 0;
    }

    &__content {
      background-color: #27292c;
      padding: 10px;
      border-radius: 5px;
    }
  }
}

.panel-sidebar-toggle {
  padding-top: 10px;
  text-align: left;

  &__input :deep(.el-switch__action) {
    background-color: #27292c !important;
    color: black !important;
  }
}

.divider {
  margin: 15px 0;
  height: 3px;
  border-radius: 2px;
  background-color: #404143;
}

.input-field {
  border-radius: 5px;
  width: 130px;
  color: white;
}

.input-field :deep(.el-input__wrapper) {
  box-shadow: none;
  background-color: #27292c;
}

.input-field :deep(.el-input__inner) {
  color: white;
  text-align: center;
}

.panel-img {
  width: 26px;
  border-radius: 10px;
  margin-right: -4px;
  z-index: 2;
}

.is-error {
  border-color: red;
  color: red;
  border: 1px solid;
}
</style>
