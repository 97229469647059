<template>
  <div class="row single-panel q-py-md" style="padding-left: 15px">
    <div
      v-if="!likedPanel && !anonymousUser"
      class="star-img"
      @click="handleStarClick"
    >
      <img src="@/assets/icons/starDeselected.svg" class="q-pe-md" />
    </div>
    <div
      v-if="likedPanel && !anonymousUser"
      class="star-img"
      @click="handleStarClick"
    >
      <img src="@/assets/icons/starSelected.svg" class="q-pe-md" />
    </div>

    <div class="col-lg-7 col-md-7 col-sm-10 q-pe-sm">
      <p>{{ panel.name }}</p>
      <div class="row q-mt-sm">
        <img class="panel-img" :src="textureUrl" />
        <div class="panel-details">
          <p>
            {{ Math.trunc(panel.size.width * 1000) }}mm x
            {{ Math.trunc(panel.size.height * 1000) }}mm
          </p>
        </div>
      </div>
    </div>
    <div class="col-lg-4 col-md-4 col-sm-12">
      <q-btn
        no-caps
        style="
          border: 1px solid #23e7a5;
          color: white;
          width: 100px;
          border-radius: 6px;
          font-weight: 400;
        "
        class="q-mt-sm"
        @click="handleSelectPanel"
      >
        {{ $t("sidebar.select") }}
        <q-tooltip>
          {{ $t("sidebar.select") }}
        </q-tooltip>
      </q-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: "SinglePanel",
  props: ["panel", "likedPanel"],
  emits: ["panelSelected", "starClicked", "anonymousUser"],
  computed: {
    textureUrl() {
      return `/assets/textures/solar_panel_texture_half_cell_${this.panel.textureId}_horizontal.png`;
    },
  },
  methods: {
    handleSelectPanel() {
      this.$emit("panelSelected", this.panel);
    },
    handleStarClick() {
      this.$emit("starClicked", this.panel);
    },
  },
};
</script>

<style>
.star-img {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.single-panel {
  background-color: #3b3c3d;
  border-radius: 3px;
  color: white;
}

.panel-details {
  background-color: #27292c;
  padding: 4px 14px;
  border-radius: 0 5px 5px 0;
}

.panel-img {
  width: 30px;
  border-radius: 10px;
  margin-right: -4px;
  z-index: 2;
}
</style>
