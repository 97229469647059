<template>
  <div class="popup-panel-form test q-py-sm q-px-md">
    <div class="row q-pb-sm">
      <h5>{{ $t("sidebar.customPanel") }}</h5>
      <img
        src="@/assets/icons/close-white.svg"
        style="margin-left: auto; cursor: pointer"
        width="15"
        @click="handleCustomPanelFormPopup(false)"
      />
    </div>
    <div class="section-bg">
      <div class="q-mx-lg q-py-sm input-title">
        <p>{{ $t("sidebar.name") }}</p>
      </div>
      <div class="q-mx-sm">
        <q-input
          dense
          filled
          :error="'name' in errors"
          :error-message="errors['name']"
          ref="nameRef"
          v-model="formData.name"
          class="form-input"
          :rules="[
            (val) => !!val || $t('errors.fieldRequired'),
            (val) => val.length <= 20 || $t('errors.maxLength', { max: 20 }),
          ]"
        />
      </div>
    </div>
    <div class="section-bg q-pb-sm">
      <div class="q-mx-lg q-py-sm input-title">
        <p>{{ $t("sidebar.selectColour") }}</p>
      </div>
      <div style="width: 90%; margin: auto" class="row q-mx-sm">
        <div class="col-sm-12 col-md-3 col-lg-3">
          <img
              style="width: 33px"
              class="panel-img"
              :class="formData.color === 54 && 'selected-color'"
              src="/assets/textures/solar_panel_texture_half_cell_54_horizontal.png"
              @click="handleSelectColor(54)"
          />
        </div>
        <div class="col-sm-12 col-md-3 col-lg-3">
          <img :class="formData.color === 60 && 'selected-color'" @click="handleSelectColor(60)" style="width: 35px" class="panel-img" src="/assets/textures/solar_panel_texture_half_cell_60_horizontal.png" />
        </div>
        <div class="col-sm-12 col-md-3 col-lg-3">
          <img :class="formData.color === 66 && 'selected-color'" @click="handleSelectColor(66)" style="width: 37px" class="panel-img" src="/assets/textures/solar_panel_texture_half_cell_66_horizontal.png" />
        </div>
        <div class="col-sm-12 col-md-3 col-lg-3">
          <img :class="formData.color === 72 && 'selected-color'" @click="handleSelectColor(72)" style="width: 40px" class="panel-img" src="/assets/textures/solar_panel_texture_half_cell_72_horizontal.png" />
        </div>
      </div>
      <div v-if="formData.color" class="preview-image q-py-sm q-px-md">
        <p class="q-pb-sm">{{ $t("panelMenu.preview") }}</p>
        <img style="width: 100%; height: 90%" :src="selectedImagePreview" />
      </div>
      <div v-if="errors.color" class="q-mx-lg q-pt-sm">
        <p class="error">{{ errors.color }}</p>
      </div>
    </div>
    <div class="section-bg q-pb-sm">
      <div class="q-mx-lg q-py-sm input-title">
        <p>{{ $t("sidebar.height") }}</p>
      </div>
      <div style="width: 85%; margin: auto" class="row">
        <div class="col-md-1 col-lg-1">
          <img src="@/assets/icons/height.svg" />
        </div>
        <div class="col-md-10 col-lg-10">
          <q-input
            dense
            filled
            suffix="mm"
            type="number"
            ref="heightRef"
            v-model="formData.size.height"
            class="form-input"
            :rules="[
              (val) => (!!val && val !== 0) || $t('errors.fieldRequired'),
              (val) => (val >= 200 && val <= 3000) || $t('errors.heightRange'),
            ]"
            hide-bottom-space="false"
          />
        </div>
      </div>
    </div>
    <div class="section-bg q-pb-sm">
      <div class="q-mx-lg q-py-sm input-title">
        <p>{{ $t("sidebar.width") }}</p>
      </div>
      <div style="width: 85%; margin: auto" class="row">
        <div class="col-md-1 col-lg-1">
          <img src="@/assets/icons/width.svg" />
        </div>
        <div class="col-md-10 col-lg-10">
          <q-input
            dense
            filled
            suffix="mm"
            type="number"
            ref="widthRef"
            v-model="formData.size.width"
            class="form-input"
            :rules="[
              (val) => (!!val && val !== 0) || $t('errors.fieldRequired'),
              (val) => (val >= 200 && val <= 3000) || $t('errors.widthRange'),
            ]"
            hide-bottom-space="false"
          />
        </div>
      </div>
    </div>
    <q-btn
      no-caps
      style="background: #23e7a5; color: black; width: 100%"
      class="q-mt-sm"
      @click="createCustomPanel"
    >
      {{ $t("sidebar.create") }}
      <q-tooltip>
        {{ $t("sidebar.create") }}
      </q-tooltip>
    </q-btn>
  </div>
</template>

<script>
import { ref } from "vue";
import { mapActions } from "vuex";
import API from "@/api/API.js";

export default {
  name: "CustomPanelForm",
  props: ["handleCustomPanelFormPopup", "activeUserId"],
  setup() {
    const nameRef = ref(null);
    const heightRef = ref(null);
    const widthRef = ref(null);

    return {
      nameRef,
      heightRef,
      widthRef,
    };
  },
  computed: {
    selectedImagePreview() {
      return `/assets/model/textures/solar_panel_texture_half_cell_${this.formData.color}_horizontal.png`;
    },
  },
  data() {
    return {
      formData: {
        userId: `${this.activeUserId}`,
        name: null,
        color: null,
        size: {
          height: null,
          width: null,
        },
        show: true,
      },
      errors: {},
    };
  },
  methods: {
    ...mapActions(["getCustomPanels", "setNewCustomPanels"]),
    handleSelectColor(selectedColor) {
      this.formData.color = selectedColor;
    },
    validateFields() {
      [this.nameRef, this.widthRef, this.heightRef].forEach((i) =>
        i.validate()
      );
      if (!this.formData.color) {
        this.errors["color"] = this.$t("errors.fieldRequired");
      } else {
        this.errors = {};
      }
      return (
        this.nameRef.hasError ||
        this.widthRef.hasError ||
        this.heightRef.hasError
      );
    },
    resetForm() {
      this.formData.name = null;
      this.formData = {};
    },
    async createCustomPanel() {
      const hasErrors = this.validateFields();
      if (hasErrors) return;

      try {
        await API.airteam3DViewer.createCustomPanel(this.formData);
        await this.getCustomPanels();
        this.resetForm();
        this.handleCustomPanelFormPopup(false);
      } catch (error) {
        if (error.response.data?.code === "duplicate_panel_name") {
          this.errors['name'] = this.$t("errors.duplicatePanelName")
        }
        console.error("Failed to create custom panel:", error);
      }
    },
  },
};
</script>

<style>
.popup-panel-form {
  text-align: left;
  background-color: #27292c;
  position: absolute;
  top: 10px;
  right: 270px;
  width: 280px;
  color: white;
  border-radius: 8px;
}

@media (min-width: 1400px) {
  .popup-panel-form {
    width: 310px;
  }
}

.section-bg {
  padding: 10px 0;
  background-color: #333537;
  border-radius: 6px;
  margin-bottom: 8px;
}

.form-input .q-field__control {
  border-radius: 6px;
  background-color: #27292c;
  height: 33px;
  color: white;
}

.form-input .q-field__native,
.q-field__suffix {
  color: white;
}

.form-input .q-field__suffix {
  margin-top: -3px;
}

.input-title {
  font-size: 17px;
}

.panel-img {
  border-radius: 5px;
}

.selected-color {
  border: 2px solid #23e7a5;
}

.error {
  color: #d92f39;
  font-size: 11px;
  text-align: left;
}

.preview-image {
  background-color: #27292c;
  position: absolute;
  top: 2px;
  right: 275px;
  width: 160px;
  border-radius: 8px;
  height: 300px;
}
</style>
