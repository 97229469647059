<template>
  <div
    :class="customPanelFormPopupOpen ? 'hide' : ''"
    class="popup-panel-selection q-py-md q-px-md"
  >
    <div class="row">
      <h5>{{ $t("panelMenu.panelSelection") }}</h5>
      <img
        src="@/assets/icons/close-white.svg"
        style="margin-left: auto; cursor: pointer"
        width="15"
        @click="handlePanelSelectionPopup(false)"
      />
    </div>

    <div class="q-mt-lg" v-if="computedLikedPanel && !anonymousUser">
      <div class="q-mb-sm row">
        <div class="panel-title">
          <p>{{ $t("panelMenu.likedPanel") }}</p>
        </div>
      </div>
      <div>
        <div class="q-my-sm">
          <SinglePanel
            :panel="computedLikedPanel"
            :anonymousUser="anonymousUser"
            :likedPanel="computedLikedPanel"
            :starClicked="handleStarClicked"
            @panelSelected="
              (payload) => $emit('panelSelected', computedLikedPanel)
            "
          />
        </div>
      </div>
    </div>
    <div class="q-mt-lg" v-if="!anonymousUser">
      <div class="q-mb-sm row">
        <div class="panel-title">
          <p>{{ $t("sidebar.custom") }}</p>
        </div>
        <div style="margin-left: auto">
          <q-btn
            no-caps
            style="background-color: #23e7a5; color: black"
            class="row q-mt-sm"
            @click="handleCustomPanelFormPopup(!customPanelFormPopupOpen)"
          >
            <img
              src="@/assets/icons/plus.svg"
              width="15"
              style="margin-right: 5px"
            />
            {{ $t("sidebar.customPanel") }}
            <q-tooltip>
              {{ $t("sidebar.customPanel") }}
            </q-tooltip>
          </q-btn>
        </div>
      </div>
      <div v-if="paginatedCustomPanels.length > 0" class="overflow-scroll">
        <div class="q-my-sm" v-for="panel in paginatedCustomPanels">
          <SinglePanel
            :anonymousUser="anonymousUser"
            :panel="panel"
            @panelSelected="(payload) => $emit('panelSelected', payload)"
            @starClicked="handleStarClicked"
          />
        </div>
      </div>
      <p
        v-if="showCustomPanelsMoreButton"
        @click="paginateCustomPanels"
        class="q-mt-sm"
      >
        <img src="@/assets/icons/chevron-down.svg" class="q-pe-md" />
        See more
      </p>
    </div>

    <div class="q-mt-lg">
      <div class="panel-title">
        <p class="q-mb-sm">{{ $t("sidebar.predefined") }}</p>
      </div>
      <div v-if="paginatedPredefinedPanels.length > 0" class="overflow-scroll">
        <div class="q-my-sm" v-for="panel in paginatedPredefinedPanels">
          <SinglePanel
            :anonymousUser="anonymousUser"
            :panel="panel"
            @panelSelected="(payload) => $emit('panelSelected', payload)"
            @starClicked="handleStarClicked"
          />
        </div>
      </div>
      <p
        v-if="showPredefinedPanelsMoreButton"
        @click="paginatePredefinedPanels"
        class="q-mt-sm"
      >
        <img src="@/assets/icons/chevron-down.svg" class="q-pe-md" />
        See more
      </p>
    </div>
  </div>
  <CustomPanelForm
    v-if="customPanelFormPopupOpen"
    :handleCustomPanelFormPopup="handleCustomPanelFormPopup"
    :activeUserId="activeUserId"
  />
</template>

<script>
import API from "@/api/API.js";
import * as panelMethods from "@/modules/panel";
import { mapActions, mapGetters } from "vuex";
import SinglePanel from "@/components/RightSidebar/SinglePanel.vue";
import CustomPanelForm from "@/components/RightSidebar/CustomPanelForm.vue";

export default {
  name: "PanelSelection",
  components: { CustomPanelForm, SinglePanel },
  props: [
    "anonymousUser",
    "panelOrientation",
    "verticalPanels",
    "horizontalPanels",
    "handlePanelSelectionPopup",
    "activeUserId",
    "likedPanelProp",
    "starClicked",
    "likedPanelId",
  ],
  emits: ["panelSelected"],
  data() {
    return {
      likedPanel: null,
      texturePath: "/assets/textures/",
      customPanelFormPopupOpen: false,
      paginationPageSize: 3,
      paginatedPredefinedPanels: (this.panelOrientation
        ? this.verticalPanels
        : this.horizontalPanels
      ).slice(0, 3),
      paginatedCustomPanels: [],
    };
  },
  watch: {
    panelOrientation(oldValue, newValue) {
      const orientationPanels = newValue
        ? this.horizontalPanels
        : this.verticalPanels;
      this.paginatedPredefinedPanels = orientationPanels.slice(
        0,
        this.paginationPageSize
      );
    },
    customPanels: {
      immediate: true,
      handler(newValue) {
        this.paginatedCustomPanels = newValue.slice(0, 3) || [];
      },
    },
  },
  computed: {
    ...mapGetters(["customPanels"]),
    showCustomPanelsMoreButton() {
      return this.customPanels.length > this.paginatedCustomPanels.length;
    },
    showPredefinedPanelsMoreButton() {
      return this.panelOrientation
        ? this.verticalPanels.length > this.paginatedPredefinedPanels.length
        : this.horizontalPanels.length > this.paginatedPredefinedPanels.length;
    },
    computedLikedPanel() {
      if (this.likedPanelProp) {
        return this.likedPanelProp;
      } else if (this.likedPanelId) {
        return this.paginatedPredefinedPanels.find(
          (panel) => panel.id === this.likedPanelId
        );
      }
      return null; // or whatever default value you want when neither condition is met
    },
  },
  methods: {
    ...Object.fromEntries(
      [...Object.entries(panelMethods)].map(([key, value]) => [
        key,
        function (...args) {
          return value.apply(this, args);
        },
      ])
    ),
    handleStarClicked(panel) {
      this.starClicked(panel);
    },
    paginatePredefinedPanels() {
      const orientationPanels = this.panelOrientation
        ? this.horizontalPanels
        : this.verticalPanels;
      const currentLength = this.paginatedPredefinedPanels.length;
      this.paginatedPredefinedPanels = this.paginatedPredefinedPanels.concat(
        orientationPanels.slice(
          currentLength,
          currentLength + this.paginationPageSize
        )
      );
    },
    paginateCustomPanels() {
      const currentLength = this.paginatedCustomPanels.length;
      this.paginatedCustomPanels = this.paginatedCustomPanels.concat(
        this.customPanels.slice(
          currentLength,
          currentLength + this.paginationPageSize
        )
      );
    },
    handleCustomPanelFormPopup(open) {
      this.customPanelFormPopupOpen = open;
    },
  },
};
</script>

<style>
.popup-panel-selection {
  text-align: left;
  background-color: #27292c;
  position: absolute;
  top: 10px;
  right: 300px;
  width: 450px;
  color: white;
  height: 85vh;
  overflow-y: scroll;
  border-radius: 8px;
}

.panel-title {
  color: white;
  font-size: 16px;
  font-weight: 400;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.overflow-scroll {
  overflow-y: auto;
  height: 260px;
}

.hide {
  display: none;
}
</style>
