export function initializeModes(vm) {
  return [
    {
      name: "SELECT",
      value: 0,
      enabled: vm.active === 0,
      enableFunction: function () {
        vm.enableMeasurementPointDragMode();
        vm.restoreDefaultNavigation();
        document.addEventListener("click", vm.selectMeasurementArea, false);
        window.addEventListener("dblclick", vm.addZoomCenter, false);
        document.addEventListener(
          "mousemove",
          vm.detectMeasurementIntersection,
          false
        );
        document.addEventListener(
          "click",
          vm.detectPersistentMeasurementIntersection,
          false
        );
      },
      disableFunction: function () {
        vm.disableMeasurementPointDragMode();
        document.removeEventListener("click", vm.selectMeasurementArea, false);
        window.removeEventListener("dblclick", vm.addZoomCenter, false);
        document.removeEventListener(
          "mousemove",
          vm.detectMeasurementIntersection,
          false
        );
        document.removeEventListener(
          "click",
          vm.detectPersistentMeasurementIntersection,
          false
        );
      },
    },
    {
      name: "DISTANCE_MEASUREMENT",
      value: 1,
      enabled: vm.active === 1,
      enableFunction: function () {
        document.addEventListener("click", vm.addMarker, false);
        document.addEventListener(
          "mousemove",
          vm.detectModelIntersection,
          false
        );
        document.addEventListener("keydown", vm.removeLastDistancePoint, false);
      },
      disableFunction: function () {
        document.removeEventListener("click", vm.addMarker, false);
        document.removeEventListener(
          "mousemove",
          vm.detectModelIntersection,
          false
        );
        document.removeEventListener(
          "keydown",
          vm.removeLastDistancePoint,
          false
        );
        vm.removeUnfinishedMeasurements();
      },
    },
    {
      name: "SOLAR_AREA",
      value: 2,
      enabled: vm.active === 2,
      enableFunction: function () {
        document.addEventListener("click", vm.addPoint, false);
        document.addEventListener(
          "mousemove",
          vm.detectModelIntersection,
          false
        );
        document.addEventListener(
          "mousemove",
          vm.detectSolarAreaIntersection,
          false
        );
        document.addEventListener("keydown", vm.removeLastPoint, false);
        window.addEventListener("mousemove", vm.stickMousePointerToDot);
        vm.showAreas();
        vm.setPanelsTransparent();
      },
      disableFunction: function (nextMode) {
        window.removeEventListener("mousemove", vm.stickMousePointerToDot);
        vm.clearMagnetEffect();
        if (nextMode === 2) return;
        document.removeEventListener("click", vm.addPoint, false);
        document.removeEventListener(
          "mousemove",
          vm.detectModelIntersection,
          false
        );
        document.removeEventListener(
          "mousemove",
          vm.detectSolarAreaIntersection,
          false
        );
        document.removeEventListener(
          "mousemove",
          vm.detectSolarAreaPointsIntersection,
          false
        );
        document.removeEventListener("keydown", vm.removeLastPoint, false);
        document.removeEventListener(
          "mousemove",
          this.detectSolarAreaPointsIntersection,
          false
        );
        document.removeEventListener("click", this.selectSolarArea, false);
        vm.cleanPanelSetup();
        vm.disablePointDragMode();
        vm.removeUnfinshedArea();

        vm.selectedArea = null;
        vm.hideAreas(false);
        vm.setPanelsOpaque();
      },
    },
    {
      name: "RESTRICTED_AREA",
      value: 6,
      enabled: vm.active === 6,
      enableFunction: function () {
        vm.cleanPanelSetup();
        // Add any additional setup code for enabling the restricted area mode here
        document.addEventListener("click", vm.addRestrictedAreaPoint, false);
        document.addEventListener(
          "mousemove",
          vm.detectModelIntersection,
          false
        );

        window.addEventListener(
          "mousemove",
          vm.stickMousePointerToDotForRestrictedArea
        );
        this.showAreas();
      },
      disableFunction: function () {
        document.removeEventListener("click", vm.addRestrictedAreaPoint, false);

        document.removeEventListener(
          "mousemove",
          vm.detectModelIntersection,
          false
        );
        window.removeEventListener(
          "mousemove",
          vm.stickMousePointerToDotForRestrictedArea
        );

        vm.removeUnfinshedRestrictedArea();
      },
    },
    {
      name: "AREA_MEASUREMENT",
      value: 4,
      enabled: vm.active === 4,
      enableFunction: function () {
        document.addEventListener("click", vm.addMeasurementPoint, false);
        document.addEventListener(
          "mousemove",
          vm.stickMousePointerToDotForMeasurementArea,
          false
        );
        document.addEventListener(
          "mousemove",
          vm.detectModelIntersection,
          false
        );
        document.addEventListener(
          "keydown",
          vm.removeLastMeasurementPoint,
          false
        );
      },
      disableFunction: function () {
        document.removeEventListener("click", vm.addMeasurementPoint, false);
        document.removeEventListener(
          "mousemove",
          vm.stickMousePointerToDotForMeasurementArea,
          false
        );
        document.removeEventListener(
          "mousemove",
          vm.detectModelIntersection,
          false
        );
        document.removeEventListener(
          "keydown",
          vm.removeLastMeasurementPoint,
          false
        );
        vm.removeUnfinishedAreaMeasurements();
        vm.clearMagnetEffect();
      },
    },
  ];
}
